import Sortable from 'sortablejs';

$(document).ready(function() {
  var tileSelectorDropdown = document.getElementById('tile-selector-dropdown')
  if (tileSelectorDropdown !== null) {
    Sortable.create(tileSelectorDropdown, {
      handle: '.drag-handle',
      animation: 150,
      onUpdate: handleTileSortUpdate
    });
  }
})

function handleTileSortUpdate() {
  var tileIds = $(".tile-list-item").map(function() {
    return $(this).data("tile-id")
  }).get()
  updateTilePositions(tileIds)
}

function updateTilePositions(tileIds) {
  var updatePositionsUrl = "/tiles/update_positions"
  $.ajax({
    url: updatePositionsUrl,
    type: 'PATCH',
    data: {
      ids: tileIds
    },
    error: function(jqXHR, textStatus, errorThrown) {
      console.error(jqXHR)
      console.error(textStatus)
      console.error(errorThrown)
    }
  })
}
